import React from 'react';
import Comment from './Comment';
import './CommentsCard.scss';

const CommentsCard = ({ comments }) => (
  <div className="comments-card-main-container card col-lg mx-2">
    <div className="card-header py-3 d-flex row align-items-center justify-content-center bg-none">
      <h5 className="m-0 font-weight-bold text-center">Comentarios</h5>
    </div>
    <div className="card-body justify-content-center">
      <div className="col-12 justify-content-center d-flex mb-4">
        <i className={`fa fa-comment fa-5x text-success`}></i>
      </div>

      <div>
        {comments ? (
          comments.map((comment, i) => <Comment content={comment.content} headerData={comment.headerData} key={i} />)
        ) : (
          <h5>No hay comentarios...</h5>
        )}
      </div>
    </div>
  </div>
);

export default CommentsCard;
