import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../assets/img/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { cleanupReports, reportErrorSelector, reportSelector } from '../../common/reports/reportsSlice';
import { fetchTutorshipsReport } from '../../common/reports/reportsThunk';
import { showAlert } from '../../common/alertService';

import './TutorshipHistory.scss';
import { tutorshipHistorySelector } from '../tutorshipSlice.js';
import { fetchTutorshipHistory } from '../tutorshipThunks';
import { userSelector } from '../../users/userSlice.js';
import { Roles } from '../../users/roles/roles.js';

const normalizeText = (text) => {
  return text
    ? text
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
    : '';
};

const TutorshipsHistory = () => {
  /** @type {import('@reduxjs/toolkit').ThunkDispatch} */
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const csvToExport = useSelector(reportSelector);
  const errorExportReport = useSelector(reportErrorSelector);
  const tutorshipHistory = useSelector(tutorshipHistorySelector);
  const user = useSelector(userSelector);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const downloadLink = useRef(null);

  useEffect(() => {
    if (user.role === Roles.TUTOR) {
      dispatch(fetchTutorshipHistory(user.id));
    } else {
      dispatch(fetchTutorshipHistory());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (downloadLink && downloadLink.current) {
      downloadLink.current.click();
      dispatch(cleanupReports());
    }
  }, [csvToExport, dispatch]);

  const downloadReports = () => {
    dispatch(fetchTutorshipsReport());
  };

  useEffect(() => {
    if (errorExportReport) {
      showAlert({ icon: 'error', title: 'Oops...', text: errorExportReport });
      return;
    }
  }, [errorExportReport]);

  if (tutorshipHistory === undefined || tutorshipHistory === null) return <div>Cargando...</div>;

  const filteredTutorships = tutorshipHistory['tutorships'].filter((tutorship) => {
    const tutorFullName = (tutorship.user?.fullName || '').replace(/\s+/g, ' ').trim();

    const studentFullName = `${tutorship.student?.name || ''} ${tutorship.student?.firstLastName || ''} ${
      tutorship.student?.secondLastName || ''
    }`
      .replace(/\s+/g, ' ')
      .trim();

    const subjectName = (tutorship.subject?.name || '').replace(/\s+/g, ' ').trim();

    return (
      normalizeText(tutorFullName).includes(normalizeText(searchTerm)) ||
      normalizeText(studentFullName).includes(normalizeText(searchTerm)) ||
      normalizeText(subjectName).includes(normalizeText(searchTerm))
    );
  });

  const totalPages = Math.ceil(filteredTutorships.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTutorships = filteredTutorships.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <div className="editTutorshipContainer">
        {user.role === Roles.ADMIN && (
          <>
            {csvToExport && (
              <a href={URL.createObjectURL(csvToExport)} ref={downloadLink} download="Reporte Linea Academica.csv"></a>
            )}
            <div className="export-report-button d-flex m-2 justify-content-end mx-auto">
              <button type="submit" className="btn btn-success" onClick={downloadReports}>
                Exportar reporte de tutorías
              </button>
            </div>
          </>
        )}
        {user.role === Roles.TUTOR && (
          <h5 className="header-message">
            A continuación, encontrarás todas las tutorías que te han solicitado.
            <br />
            ¡Gracias por contribuir al aprendizaje de tantos estudiantes!
          </h5>
        )}
        <div className="card shadow mb-4">
          <div className="card-header py-3 header-tutorship-history">
            <h6 className="m-0 p-2 font-weight-bold text-primary">Historial de Tutorías</h6>
            <div className="d-flex">
              <input
                type="text"
                placeholder={
                  user.role === Roles.ADMIN
                    ? 'Buscar por tutor, estudiante o materia'
                    : 'Buscar por estudiante o materia'
                }
                value={searchTerm}
                onChange={handleSearch}
                className="form-control me-2 mr-2"
                style={{ width: '300px' }}
              />
              <button className="btn btn-primary" onClick={() => setSearchTerm('')}>
                Limpiar
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {user.role === Roles.ADMIN && <th>Tutor</th>}
                    <th>Materia</th>
                    <th>Tema</th>
                    <th>Estudiante</th>
                    {user.role === Roles.ADMIN && <th>Celular estudiante</th>}
                    <th>Fecha tutoría</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTutorships.map((tutorship) => {
                    const rawDate = tutorship?.scheduledDate;
                    const formattedDate = rawDate ? new Date(rawDate).toISOString().slice(0, 19).replace('T', ' ') : '';

                    return (
                      <tr key={tutorship.id}>
                        {user.role === Roles.ADMIN && (
                          <td
                            title={tutorship.user?.phoneNumber}
                            onClick={() => navigate(`/tutores/detalle/${tutorship.user?.id}`)}
                            className="clickable-row"
                          >
                            {tutorship.user?.fullName}
                          </td>
                        )}
                        <td>{tutorship.subject?.name}</td>
                        <td>{tutorship.topic}</td>
                        <td>
                          {tutorship.student?.name} {tutorship.student?.firstLastName}{' '}
                          {tutorship.student?.secondLastName}
                        </td>
                        {user.role === Roles.ADMIN && <td>{tutorship.student.cellphoneNumber}</td>}
                        <td>{formattedDate}</td>
                        <td className={tutorship.status.statusFlag}>
                          {tutorship.status.statusName || 'No hay estado'}
                        </td>
                        <td className="g-1">
                          {tutorship.tutorConfirmationStatusId === 1 || tutorship.tutorConfirmationStatusId === 2 ? (
                            <>
                              {tutorship.tutorRatingStatusId !== 1 ? (
                                <>
                                  {user.role === Roles.ADMIN ? (
                                    <>
                                      <i
                                        title="Editar tutoría"
                                        role="button"
                                        onClick={() => navigate(`/tutorias/editar/${tutorship.id}`)}
                                        className="fa-solid fa-pen-to-square fa-2x text-warning p-1"
                                      />
                                      <i
                                        title="Valorar estudiante"
                                        role="button"
                                        className="fas fa-comment fa-2x text-success"
                                        onClick={() => navigate(`/tutorias/${tutorship.id}/valorarEstudiante`)}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <i
                                        title="Valorar estudiante"
                                        role="button"
                                        className="fas fa-comment fa-2x text-success"
                                        onClick={() => navigate(`/tutorias/${tutorship.id}/valorarEstudiante`)}
                                      />
                                    </>
                                  )}
                                </>
                              ) : (
                                <p>Tutoría valorada</p>
                              )}
                            </>
                          ) : tutorship.tutorConfirmationStatusId === 5 ? (
                            <p>Tutoría cancelada por estudiante</p>
                          ) : tutorship.tutorConfirmationStatusId === 6 ? (
                            <>
                              {user.role === Roles.ADMIN ? (
                                <>
                                  <i
                                    title="Editar tutoría"
                                    role="button"
                                    onClick={() => navigate(`/tutorias/editar/${tutorship.id}`)}
                                    className="fa-solid fa-pen-to-square fa-2x text-warning p-1"
                                  />
                                  <i
                                    title="Valorar estudiante"
                                    role="button"
                                    className="fas fa-comment fa-2x text-success"
                                    onClick={() => navigate(`/tutorias/${tutorship.id}/valorarEstudiante`)}
                                  />
                                </>
                              ) : (
                                <p>Tutoría cancelada por otras razones</p>
                              )}
                            </>
                          ) : (
                            <>
                              {user.role === Roles.ADMIN ? (
                                <>
                                  <i
                                    title="Editar tutoría"
                                    role="button"
                                    onClick={() => navigate(`/tutorias/editar/${tutorship.id}`)}
                                    className="fa-solid fa-pen-to-square fa-2x text-warning p-1"
                                  />
                                  <i
                                    title="Valorar estudiante"
                                    role="button"
                                    className="fas fa-comment fa-2x text-success"
                                    onClick={() => navigate(`/tutorias/${tutorship.id}/valorarEstudiante`)}
                                  />
                                </>
                              ) : (
                                <p>Debes aceptar o rechazar el espacio</p>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="pagination">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="btn btn-primary"
                >
                  Anterior
                </button>
                <span className="mx-2">
                  Página {currentPage} de {totalPages}
                </span>
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="btn btn-primary"
                >
                  Siguiente
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="justify-content-center w-75 mx-auto">
          <p id="editTutorshipText" className="text-center mt-4">
            Recuerda que gracias a ti los niveles de deserción disminuyen y los sueños aumentan
          </p>
          <img className="mx-auto d-block editTutorshipLogo" src={Logo} alt="Logo ATV" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default TutorshipsHistory;
