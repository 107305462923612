import { createSlice } from '@reduxjs/toolkit';
import { fetchAcademicModality } from './academicModalityThunk';

export const academicModalitySlice = createSlice({
  name: 'academicModality',
  initialState: {
    /** @type {boolean} */
    isLoading: false,

    /** @type {string | null} */
    error: null,

    /** @type {any?} */
    data: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAcademicModality.pending, (state, _action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAcademicModality.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchAcademicModality.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  },
});

export const academicModalitySelector = (state) => state.academicModality.data;
