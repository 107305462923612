import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/users/userSlice';
import studyGroupRequestReducer from '../features/studyGroups/studyGroupRequestSlice';
import tutorReducer from '../features/tutors/tutorSlice';
import studyGroupReducer from '../features/studyGroups/studyGroupSlice';
import studyGroupModalityReducer from '../features/studyGroupModality/studyGroupModalitySlice';
import { departmentSlice } from '../features/location/departments/departmentSlice';
import { municipalitySlice } from '../features/location/municipalities/municipalitySlice';
import { genderSlice } from '../features/common/gender/genderSlice';
import { universitySlice } from '../features/universities/universitySlice';
import { careerSlice } from '../features/universities/careers/careerSlice';
import { documentsTypeSlice } from '../features/common/documentType/documentTypeSlice';
import { subjectSlice } from '../features/subjects/subjectSlice';
import { tutorshipSlice } from '../features/tutorship/tutorshipSlice';
import { tutorshipStatusSlice } from '../features/common/tutorshipStatus/tutorshipStatusSlice';
import { reportsSlice } from '../features/common/reports/reportsSlice';
import { tutorToStudentRatingSlice } from '../features/tutorship/tutorToStudentRating/tutorToStudentRatingSlice';
import { tutorshipStatisticsSlice } from '../features/statistics/tutorshipStatisticsSlice';
import { academicModalitySlice } from '../features/student/academicModality/academicModalitySlice';

export default configureStore({
  reducer: {
    user: userReducer,
    tutor: tutorReducer,
    studyGroup: studyGroupReducer,
    studyGroupModality: studyGroupModalityReducer,
    studyGroupRequest: studyGroupRequestReducer,
    department: departmentSlice.reducer,
    academicModality: academicModalitySlice.reducer,
    municipality: municipalitySlice.reducer,
    gender: genderSlice.reducer,
    university: universitySlice.reducer,
    subject: subjectSlice.reducer,
    career: careerSlice.reducer,
    documentType: documentsTypeSlice.reducer,
    tutorship: tutorshipSlice.reducer,
    tutorshipStatus: tutorshipStatusSlice.reducer,
    reports: reportsSlice.reducer,
    tutorToStudentRating: tutorToStudentRatingSlice.reducer,
    tutorshipStatistics: tutorshipStatisticsSlice.reducer,
  },
});
