import React, { useEffect, useState } from 'react';
import './Register.css';
import { useDispatch, useSelector } from 'react-redux';
import { departmentsSelector } from '../../location/departments/departmentSlice';
import { fetchDepartments } from '../../location/departments/departmentThunk';
import { municipalitiesSelector } from '../../location/municipalities/municipalitySlice';
import { fetchMunicipalitiesByDepartmentId } from '../../location/municipalities/municipalityThunk';
import { fetchGenders } from '../../common/gender/genderThunk';
import { universitiesSelector } from '../../universities/universitySlice';
import { fetchUniversities } from '../../universities/universityThunk';
import { fetchCareersByUniversityId } from '../../universities/careers/careerThunk';
import { careersSelector } from '../../universities/careers/careerSlice';
import { showAlert } from '../../common/alertService';
import { createNewUser } from '../userThunks';
import { documentsTypeSelector } from '../../common/documentType/documentTypeSlice';
import { fetchDocumentsType } from '../../common/documentType/documentTypeThunk';
import { gendersSelector } from '../../common/gender/genderSlice';
import { userErrorSelector, userSuccessSelector } from '../userSlice';
import { useNavigate } from 'react-router-dom';
import { INSTITUTIONAL_EMAIL_REGEX } from '../../common/constants';
import { academicModalitySelector } from '../../student/academicModality/academicModalitySlice';
import { fetchAcademicModality } from '../../student/academicModality/academicModalityThunk';

const Register = () => {
  /** @type {import('@reduxjs/toolkit').ThunkDispatch} */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const departments = useSelector(departmentsSelector);
  const municipalities = useSelector(municipalitiesSelector);
  const genders = useSelector(gendersSelector);
  const universities = useSelector(universitiesSelector);
  const careers = useSelector(careersSelector);
  const academicModalities = useSelector(academicModalitySelector);
  const documentsType = useSelector(documentsTypeSelector);
  const successMessage = useSelector(userSuccessSelector);
  const errorMessage = useSelector(userErrorSelector);

  const [documentNumber, setDocumentNumber] = useState('');
  const [documentTypeId, setDocumentTypeId] = useState('');
  const [repeatDocument, setRepeatDocument] = useState('');
  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [firstLastName, setFirstLastName] = useState('');
  const [secondLastName, setSecondLastName] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [municipalityId, setMunicipalityId] = useState('');
  const [genderId, setGenderId] = useState('');
  const [address, setAddress] = useState('');
  const [universityId, setUniversityId] = useState('');
  const [careerId, setCareerId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [cellphoneNumber, setCellPhoneNumber] = useState('');
  const [institutionalEmail, setInstitutionalEmail] = useState('');
  const [personalEmail, setPersonalEmail] = useState('');
  const [preferredEmail, setPreferredEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [habeasDataChecked, setHabeasDataChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [studyModalityId, setStudyModality] = useState('');
  useEffect(() => {
    if (departments === null) {
      dispatch(fetchDepartments());
    }
    if (genders === null) {
      dispatch(fetchGenders());
    }
    if (universities === null) {
      dispatch(fetchUniversities());
    }
    if (documentsType === null) {
      dispatch(fetchDocumentsType());
    }
    if (academicModalities === null) {
      dispatch(fetchAcademicModality());
    }
  }, [departments, genders, universities, documentsType, academicModalities, dispatch]);

  function onDepartmentChange(event) {
    dispatch(fetchMunicipalitiesByDepartmentId(event.target.value));
    setDepartmentId(event.target.value);
  }

  function onUniversityChange(event) {
    dispatch(fetchCareersByUniversityId(event.target.value));
    setUniversityId(event.target.value);
  }

  function togglePasswordVisibility() {
    setShowPassword(!showPassword);
  }

  function formatName(name) {
    return name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  function handleSubmit(event) {
    event.preventDefault();

    const numericRegex = /^[0-9]+$/;

    if (documentNumber !== repeatDocument) {
      return showAlert({ icon: 'error', title: 'Oops...', text: 'Los documentos no coinciden.' });
    }

    if (
      !numericRegex.test(documentNumber) ||
      !numericRegex.test(repeatDocument) ||
      !numericRegex.test(cellphoneNumber)
    ) {
      return showAlert({ icon: 'error', title: 'Oops...', text: 'Los campos númericos no pueden llevar letras.' });
    }

    if (password !== repeatPassword) {
      return showAlert({ icon: 'error', title: 'Oops...', text: 'Las contraseñas no coinciden.' });
    }

    if (
      !studyModalityId ||
      !documentTypeId ||
      !documentNumber ||
      !repeatDocument ||
      !firstName ||
      !firstLastName ||
      !secondLastName ||
      !institutionalEmail ||
      !personalEmail ||
      !preferredEmail ||
      !departmentId ||
      !municipalityId ||
      !address ||
      !universityId ||
      !careerId ||
      !genderId ||
      !cellphoneNumber ||
      !password ||
      !repeatPassword
    ) {
      return showAlert({ icon: 'error', title: 'Oops...', text: 'Debes completar todos los campos obligatorios *' });
    }

    if (!INSTITUTIONAL_EMAIL_REGEX.test(institutionalEmail)) {
      return showAlert({
        icon: 'error',
        title: 'Dato inválido',
        text: 'El correo ingresado en "Correo institucional" no es un correo institucional (no termina en .edu.co)',
      });
    }

    if (cellphoneNumber.length < 10) {
      return showAlert({
        icon: 'error',
        title: 'Oops...',
        text: 'El número de celular debe tener al menos 10 dígitos',
      });
    }

    if (documentNumber.length < 6) {
      return showAlert({
        icon: 'error',
        title: 'Oops...',
        text: 'El número de documento es muy corto',
      });
    }

    if (!habeasDataChecked) {
      return showAlert({ icon: 'error', title: 'Oops...', text: 'Debes aceptar los términos y condiciones.' });
    }

    const user = {
      studyModalityId,
      documentTypeId,
      documentNumber,
      firstName: formatName(firstName),
      secondName: formatName(secondName),
      firstLastName: formatName(firstLastName),
      secondLastName: formatName(secondLastName),
      departmentId,
      municipalityId,
      genderId,
      address,
      universityId,
      careerId,
      phoneNumber,
      cellphoneNumber,
      institutionalEmail,
      personalEmail,
      preferredEmail,
      password,
      habeasDataChecked,
    };
    dispatch(createNewUser(user));
  }

  useEffect(() => {
    if (successMessage) {
      showAlert({ icon: 'success', title: 'Éxito', text: successMessage }).then(() => {
        window.location.href = '/login';
      });
    }
    if (errorMessage) {
      showAlert({ icon: 'error', title: 'Oops...', text: errorMessage });
    }
  }, [navigate, successMessage, errorMessage]);

  if (departments === null) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <div className="container">
        <div className="card o-hidden border-0 shadow-lg my-5">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-5 d-none d-lg-block bg-register-image"></div>
              <div className="col-lg-7">
                <div className="p-5">
                  <div className="text-center">
                    <h1 className="h4 text-gray-900 mb-4">¡Crear una cuenta!</h1>
                  </div>
                  <form className="user" onSubmit={handleSubmit}>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <label htmlFor="documentTypeId">Tipo de documento *</label>
                        <select
                          value={documentTypeId}
                          onChange={(event) => setDocumentTypeId(event.target.value)}
                          className="form-control "
                          id="documentTypeId"
                        >
                          <option value=""></option>
                          {documentsType?.map((documentType) => (
                            <option value={documentType.id} key={documentType.id}>
                              {documentType.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <label htmlFor="documentNumber">Documento *</label>
                        <input
                          type="text"
                          className="form-control form-control-user-register"
                          id="documentNumber"
                          placeholder="Documento"
                          required
                          onChange={(event) => setDocumentNumber(event.target.value)}
                          value={documentNumber}
                        ></input>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label htmlFor="repeatDocument">Repetir documento *</label>
                        <input
                          type="text"
                          className="form-control form-control-user-register"
                          id="repeatDocument"
                          placeholder="Repetir documento"
                          required
                          onChange={(event) => setRepeatDocument(event.target.value)}
                          value={repeatDocument}
                        ></input>
                      </div>
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <label htmlFor="firstName">Primer nombre *</label>
                        <input
                          type="text"
                          className="form-control form-control-user-register text-capitalize"
                          id="firstName"
                          placeholder="Primer nombre"
                          required
                          onChange={(event) => setFirstName(event.target.value)}
                          value={firstName}
                        ></input>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label htmlFor="secondName">Segundo nombre</label>
                        <input
                          type="text"
                          className="form-control form-control-user-register text-capitalize"
                          id="secondName"
                          placeholder="Segundo nombre"
                          onChange={(event) => setSecondName(event.target.value)}
                          value={secondName}
                        ></input>
                      </div>
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <label htmlFor="firstLastName">Primer apellido *</label>
                        <input
                          type="text"
                          className="form-control form-control-user-register text-capitalize"
                          id="firstLastName"
                          placeholder="Primer apellido"
                          required
                          onChange={(event) => setFirstLastName(event.target.value)}
                          value={firstLastName}
                        ></input>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label htmlFor="secondLastName">Segundo apellido *</label>
                        <input
                          type="text"
                          className="form-control form-control-user-register text-capitalize"
                          id="secondLastName"
                          placeholder="Segundo apellido"
                          required
                          onChange={(event) => setSecondLastName(event.target.value)}
                          value={secondLastName}
                        ></input>
                      </div>
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <label htmlFor="studyModality">Modalidad académica *</label>

                        <select
                          value={studyModalityId}
                          onChange={(event) => setStudyModality(event.target.value)}
                          className="form-control "
                          id="studyModality"
                        >
                          <option></option>
                          {academicModalities?.map((academicModality) => (
                            <option value={academicModality.id} key={academicModality.id}>
                              {academicModality.description}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <label htmlFor="universityId">Universidad *</label>
                        <select
                          value={universityId}
                          className="form-control "
                          onChange={onUniversityChange}
                          id="universityId"
                        >
                          <option></option>
                          {universities?.map((university) => (
                            <option value={university.id} key={university.id}>
                              {university.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="careerId">Carrera *</label>
                        <select
                          value={careerId}
                          onChange={(event) => setCareerId(event.target.value)}
                          className="form-control "
                          id="careerId"
                        >
                          <option></option>
                          {careers?.map((career) => (
                            <option value={career.id} key={career.id}>
                              {career.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="institutionalEmail">Correo institucional *</label>
                      <input
                        type="email"
                        className="form-control form-control-user-register"
                        id="institutionalEmail"
                        placeholder="Correo institucional"
                        onChange={(event) => setInstitutionalEmail(event.target.value)}
                        value={institutionalEmail}
                      ></input>
                    </div>
                    <div className="form-group">
                      <label htmlFor="personalEmail">Correo personal *</label>
                      <input
                        type="email"
                        className="form-control form-control-user-register"
                        id="personalEmail"
                        placeholder="Correo personal"
                        onChange={(event) => setPersonalEmail(event.target.value)}
                        value={personalEmail}
                      ></input>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label htmlFor="preferredEmail">Selecciona un correo principal *</label>
                        <select
                          value={preferredEmail}
                          onChange={(event) => setPreferredEmail(event.target.value)}
                          className="form-control "
                          id="preferredEmail"
                        >
                          <option></option>
                          <option value="1">Correo institucional</option>
                          <option value="2">Correo personal</option>
                        </select>
                      </div>
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <label htmlFor="departmentId">Departamento de residencia *</label>
                        <select
                          value={departmentId}
                          className="form-control "
                          onChange={onDepartmentChange}
                          id="departmentId"
                        >
                          <option></option>
                          {departments.map((department) => (
                            <option value={department.id} key={department.id}>
                              {department.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <label htmlFor="municipalityId">Municipio donde vive *</label>
                        <select
                          value={municipalityId}
                          onChange={(event) => setMunicipalityId(event.target.value)}
                          className="form-control "
                          id="municipalityId"
                        >
                          <option></option>
                          {municipalities?.map((municipality) => (
                            <option value={municipality.id} key={municipality.id}>
                              {municipality.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="genderId">Género *</label>
                        <select
                          value={genderId}
                          onChange={(event) => setGenderId(event.target.value)}
                          className="form-control "
                          id="genderId"
                        >
                          <option value=""></option>
                          {genders?.map((gender) => (
                            <option value={gender.id} key={gender.id}>
                              {gender.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="address">Dirección *</label>
                      <input
                        type="text"
                        className="form-control form-control-user-register"
                        id="address"
                        placeholder="Dirección"
                        onChange={(event) => setAddress(event.target.value)}
                        value={address}
                      ></input>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <label htmlFor="phoneNumber">Teléfono</label>
                        <input
                          type="text"
                          className="form-control form-control-user-register"
                          id="phoneNumber"
                          placeholder="Teléfono"
                          onChange={(event) => setPhoneNumber(event.target.value)}
                          value={phoneNumber}
                        ></input>
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="cellPhoneNumber">Celular *</label>
                        <input
                          type="text"
                          className="form-control form-control-user-register"
                          id="cellPhoneNumber"
                          placeholder="Celular"
                          onChange={(event) => setCellPhoneNumber(event.target.value)}
                          value={cellphoneNumber}
                        ></input>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <label htmlFor="password">Contraseña *</label>
                        <div className="input-group">
                          <input
                            type={showPassword ? 'text' : 'password'}
                            className="form-control form-control-user-register"
                            id="password"
                            placeholder="Contraseña"
                            onChange={(event) => setPassword(event.target.value)}
                            value={password}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="repeatPassword">Repetir contraseña *</label>
                        <div className="input-group">
                          <input
                            type={showPassword ? 'text' : 'password'}
                            className="form-control form-control-user-register"
                            id="repeatPassword"
                            placeholder="Repite la contraseña"
                            onChange={(event) => setRepeatPassword(event.target.value)}
                            value={repeatPassword}
                          />
                          <div className="input-group-append">
                            <button className="btn btn-primary" type="button" onClick={togglePasswordVisibility}>
                              {showPassword ? (
                                <i className="fa-regular fa-eye"></i>
                              ) : (
                                <i className="fa-regular fa-eye-slash"></i>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr></hr>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="habeasDataChecked"
                        onChange={(event) => setHabeasDataChecked(event.target.checked)}
                        value={habeasDataChecked}
                      ></input>
                      <label className="form-check-label" htmlFor="habeasDataChecked">
                        <a className="small" href={process.env.REACT_APP_STUDENTS_APP_URL + '/pages/habeas_data.php'}>
                          Autorizas el tratamiento de datos
                        </a>
                      </label>
                    </div>
                    <hr></hr>
                    <button className="btn btn-primary btn-user btn-block">Registrar usuario</button>
                  </form>
                  <hr></hr>

                  <div className="text-center">
                    <a className="small" href="login-inicio.html">
                      ¿Ya tienes una cuenta? ¡Accede!
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Register;
